import React, {useContext, useMemo} from 'react';
import TopBar from '../core/top-bar/top-bar';
import Footer from '../core/footer/footer';
import AutonomyLightImage from '../../assets/images/autonomy-light.png';
import PerformanceLightImage from '../../assets/images/performance-light.png';
import LiquidityLightImage from '../../assets/images/liquidity-light.png';
import AutonomyDarkImage from '../../assets/images/autonomy-dark.png';
import PerformanceDarkImage from '../../assets/images/performance-dark.png';
import LiquidityDarkImage from '../../assets/images/liquidity-dark.png';
import TeamLightIcon from '../../assets/icons/team-light.svg';
import ContributeLightIcon from '../../assets/icons/contribute-light.svg';
import GrantApplyLightIcon from '../../assets/icons/grant-apply-light.svg';
import HubLightIcon from '../../assets/icons/hub-light.svg';
import TeamDarkIcon from '../../assets/icons/team-dark.svg';
import ContributeDarkIcon from '../../assets/icons/contribute-dark.svg';
import GrantApplyDarkIcon from '../../assets/icons/grant-apply-dark.svg';
import HubDarkIcon from '../../assets/icons/hub-dark.svg';
import FactoryLightImage from '../../assets/images/factory-light.png';
import HubLightImage from '../../assets/images/hub-light.png';
import FactoryDarkImage from '../../assets/images/factory-dark.png';
import HubDarkImage from '../../assets/images/hub-dark.png';

import {Theme, ThemeContext} from '../core/theme-context';
import './home.scss';

const Home = () => {
    const {theme} = useContext(ThemeContext);
    const inverseTheme = useMemo<Theme>(() => theme === 'dark' ? 'light' : 'dark', [theme]);

    return (
        <div className={'home ' + theme}>
            <div className='content'>
                <TopBar className='top-bar'/>

                <section className='hero-section blurred'>
                    <h1 className='header main'>Home
                        <div>of the</div>
                        RollApps
                    </h1>
                    <p>
                        Dymension is a home for easily deployable and lightning fast app-chains, called RollApps.
                    </p>
                    <div className='action-list'>
                        <a className={'button ' + theme} href='https://portal.dymension.sx/' target='_blank'>Enter Portal</a>
                    </div>
                </section>

                <div className='mobile-image factory'>
                    <img src={theme === 'light' ? FactoryLightImage : FactoryDarkImage} alt='factory-image'/>
                </div>

                <section className='rollapp-chains-section blurred'>
                    <h2 className='header'>RollApps</h2>
                    <p>Easily deploy your own consensus-free blockchain and accrue more token value with greater security.</p>
                    <a className={'button ' + inverseTheme}
                       href='https://docs.dymension.xyz/'
                       target='_blank'>Build a RollApp</a>
                </section>

                <div className='mobile-image hub'>
                    <img src={theme === 'light' ? HubLightImage : HubDarkImage} alt='factory-image'/>
                </div>

                <section className='dymension-hub-section blurred'>
                    <h2 className='header'>Dymension Hub</h2>
                    <p>A specialized modular blockchain built to empower, unite and service RollApps.</p>
                    <a className={'button ' + inverseTheme}
                       href='https://docs.dymension.xyz/validate/dymension-hub/overview'
                       target='_blank'>Run a Node</a>
                </section>

                <section className='advantages-section blurred-sm'>
                    <h2 className='header blurred'>Shift the Consensus with RollApps</h2>
                    <div className='advantage-list'>
                        <div className='advantage blurred'>
                            <img src={theme === 'light' ? AutonomyLightImage : AutonomyDarkImage} alt='autonomy-image'/>
                            <h3 className='header'>Autonomy</h3>
                            <p className='info'>
                                Your own blockchain with maximum flexibility and minimum overhead.
                            </p>
                        </div>

                        <div className='advantage blurred'>
                            <img src={theme === 'light' ? PerformanceLightImage : PerformanceDarkImage} alt='autonomy-image'/>
                            <h3 className='header'>Performance</h3>
                            <p className='info'>
                                Scale just like traditional low latent servers by outsourcing consensus to the dymension hub.
                            </p>
                        </div>

                        <div className='advantage blurred'>
                            <img src={theme === 'light' ? LiquidityLightImage : LiquidityDarkImage} alt='autonomy-image'/>
                            <h3 className='header'>Liquidity</h3>
                            <p className='info'>
                                Instantly tap into the concentrated liquidity of the dymension hub.
                            </p>
                        </div>
                    </div>
                </section>

                <section className='summary-links-section blurred'>
                    <h2 className='header'>Join the network effect</h2>
                    <div className='links-container'>
                        <a className={'button ' + inverseTheme}
                           href='https://docs.dymension.xyz/build/roller/'
                           target='_blank'
                        >
                            <img src={theme === 'light' ? GrantApplyLightIcon : GrantApplyDarkIcon}
                                 alt='grant-apply-icon'
                                 className='icon'/>
                            <h4>Build a RollApp</h4>
                        </a>
                        <a className={'button ' + inverseTheme}
                           href='https://docs.dymension.xyz/validate/dymension-hub/overview'
                           target='_blank'
                        >
                            <img src={theme === 'light' ? HubLightIcon : HubDarkIcon} alt='hub-icon' className='icon'/>
                            <h4>Validate the network</h4>
                        </a>
                        <a className={'button ' + inverseTheme} href='https://github.com/dymensionxyz/' target='_blank'>
                            <img src={theme === 'light' ? ContributeLightIcon : ContributeDarkIcon}
                                 alt='contribute-icon'
                                 className='icon'/>
                            <h4>Contribute on GitHub</h4>
                        </a>
                        <a className={'button ' + inverseTheme}
                           href='https://dymension.notion.site/Dymension-Careers-43b00cec33cb48f4bf64524a62612fba'
                           target='_blank'
                        >
                            <img src={theme === 'light' ? TeamLightIcon : TeamDarkIcon} alt='team-icon' className='icon'/>
                            <h4>Join the core team</h4>
                        </a>
                    </div>
                </section>

                <Footer className='footer blurred'/>
            </div>
        </div>
    );
};

export default Home;
