import React from 'react';
import { ThemeContext, ThemeContextProvider } from '../core/theme-context';
import { LoaderContext, LoaderContextProvider } from '../core/loader-context';
import Spinner from '../core/spinner/spinner';
import Home from '../home/home';
import './app.scss';

const App = () => {
    return <LoaderContextProvider>
        <ThemeContextProvider>
            <LoaderContext.Consumer>
                {({ loading }) => <ThemeContext.Consumer>
                    {({ theme }) => <div className={'app ' + theme}>
                        {loading ? <Spinner fullPage /> : <Home />}
                    </div>}
                </ThemeContext.Consumer>}
            </LoaderContext.Consumer>
        </ThemeContextProvider>
    </LoaderContextProvider>;
};

export default App;
