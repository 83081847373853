import React, { createContext, ReactNode, useState } from 'react';

export type Theme = 'dark' | 'light';

export const THEME_KEY = 'theme-key';

export interface ThemeContextValue {
    theme: Theme,
    toggleTheme: () => void;
}

export const ThemeContext = createContext({} as ThemeContextValue);

export const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
    const [theme, setTheme] = useState<Theme>(getSavedTheme());

    const toggleTheme = (): void => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        localStorage.setItem(THEME_KEY, newTheme);
        setTheme(newTheme);
    };

    return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

const getSavedTheme = (): Theme => {
    return localStorage.getItem(THEME_KEY) === 'light' ? 'light' : 'dark';
};

