import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './modules/app/app';
import './fonts/TWKEverett/TWKEverett-Medium.woff2';
import './styles/index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
