import React, { useContext } from 'react';
import { ThemeContext } from '../theme-context';
import { ReactComponent as DymensionFullLogo } from '../../../assets/images/dymension-full-logo.svg';
import './footer.scss';

const Footer = ({ className = '' }: { className?: string }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`footer ${theme} ${className}`}>
            <div className='footer-column logo'>
                <span className='full-logo'> <DymensionFullLogo /></span>
                <p className='all-rights-reserved'>Home of the RollApps <span className='barber'>💈</span></p>
            </div>

            <div className='footer-column'>
                <span className='footer-header'>Community</span>
                <a className='link' href='https://twitter.com/dymension' target='_blank'>Twitter</a>
                <a className='link' href='https://discord.gg/dymension' target='_blank'>Discord</a>
                <a className='link' href='https://t.me/dymensionXYZ' target='_blank'>Telegram</a>
                <a className='link' href='https://medium.com/@dymension' target='_blank'>Medium</a>
            </div>

            <div className='footer-column'>
                <span className='footer-header'>Developers</span>
                <a className='link' href='https://github.com/dymensionxyz' target='_blank'>GitHub</a>
                <a className='link' target='_blank' href='https://docs.dymension.xyz/'>Docs</a>
                <a className='link'
                   target='_blank'
                   href='https://firebasestorage.googleapis.com/v0/b/dymension-3f01e.appspot.com/o/Brand-Kit%2FBrand-Kit.zip?alt=media'
                >
                    Brand Kit
                </a>
            </div>
        </div>
    );
};

export default Footer;
