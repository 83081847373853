import React, { useContext } from 'react';
import { ReactComponent as DymensionFullLogo } from '../../../assets/images/dymension-full-logo.svg';
import { ReactComponent as LightIcon } from '../../../assets/icons/light.svg';
import { ReactComponent as DarkIcon } from '../../../assets/icons/dark.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/twitter.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from '../../../assets/icons/discord.svg';
import { ReactComponent as MediumIcon } from '../../../assets/icons/medium.svg';
import { ThemeContext } from '../theme-context';
import './top-bar.scss';

const TopBar = ({ className = '' }: { className?: string }) => {
    let { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className={'top-bar ' + theme + ' ' + className}>
            <span className='logo'><DymensionFullLogo /></span>
            <div className='actions'>
                <a className='action dark-mode' onClick={toggleTheme}>
                    {theme === 'dark' ? <DarkIcon /> : <LightIcon />}
                </a>
                <a className='action' href='https://twitter.com/dymension' target='_blank'><TwitterIcon /></a>
                <a className='action' href='https://discord.gg/dymension' target='_blank'><DiscordIcon /></a>
                <a className='action' href='https://t.me/dymensionXYZ' target='_blank'><TelegramIcon /></a>
                <a className='action' href='https://medium.com/@dymension' target='_blank'><MediumIcon /></a>
            </div>
        </div>
    );
};

export default TopBar;
