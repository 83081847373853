import React, { createContext, ReactNode, useEffect, useState } from 'react';
import HomeBackgroundLight from '../../assets/images/home-background-light.png';
import HomeBackgroundDark from '../../assets/images/home-background-dark.png';
import HomeBackgroundGridDark from '../../assets/images/home-background-grid-dark.png';
import HomeBackgroundGridLight from '../../assets/images/home-background-grid-light.png';

const MOBILE_WIDTH = 600;

export interface LoaderContextValue {
    loading: boolean,
}

export const LoaderContext = createContext({} as LoaderContextValue);

export const LoaderContextProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (window.innerWidth > MOBILE_WIDTH) {
            setLoading(true);
        }
        Promise.all([
            loadImage(HomeBackgroundLight),
            loadImage(HomeBackgroundDark),
            loadImage(HomeBackgroundGridDark),
            loadImage(HomeBackgroundGridLight)
        ]).then(() => setTimeout(() => setLoading(false), 50));
    }, []);

    const loadImage = (source: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve();
            image.onerror = () => reject();
            image.src = source;
        });
    };

    return <LoaderContext.Provider value={{ loading }}>{children}</LoaderContext.Provider>;
};

