import React, { useContext } from 'react';
import { ThemeContext } from '../theme-context';
import { ReactComponent as DymensionLogo } from '../../../assets/images/dymension-logo.svg';
import './spinner.scss';

const Spinner = ({ fullPage = false }: { className?: string, fullPage?: boolean }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <div className={`container ${theme} ${fullPage ? 'full' : ''}`}>
            <span className='spinner'>
                <DymensionLogo />
            </span>
        </div>
    );
};

export default Spinner;
